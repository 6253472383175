<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h2>Contract Manage</h2>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-card>
          <b-tabs v-model="activeTab" fill>
            <b-tab title="Information" active>
              <b-row>
                <b-col class="text-right">
                  <b-button
                    @click="goBack()"
                    type="button"
                    class="mr-1"
                    variant="outline-danger"
                  >
                    Back
                  </b-button>
                  <b-button
                    @click="onDuplicate()"
                    type="button"
                    class="mr-1"
                    variant="info"
                  >
                    Duplicate
                  </b-button>
                  <b-button type="button" variant="success" @click="onEdit()">
                    Edit
                  </b-button>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Contract No." label-for="v-contract_no">
                    <b-form-input
                      disabled
                      v-model="formData.refnum"
                      id="v-contract_no"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Contract Type"
                    label-for="v-contract_type"
                  >
                    <b-form-input
                      disabled
                      v-model="formData.contract_type"
                      id="v-contract_type"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Start Date" label-for="v-start_date">
                    <b-form-input disabled v-model="previewStartDate" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="End Date" label-for="v-end_date">
                    <b-form-input disabled v-model="previewEndDate" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Chargeable Token"
                    label-for="v-chargeable_type"
                  >
                    <b-form-select
                      disabled
                      id="v-chargeable_type"
                      v-model="formData.chargeable_type"
                      :options="[
                        { text: 'Hours', value: 'H' },
                        { text: 'Token', value: 'T' },
                      ]"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <b-form-group
                    label="Contract Eligible"
                    label-for="v-contract_eligible"
                  >
                    <b-input-group :append="chargeableType">
                      <b-form-input
                        disabled
                        id="v-contract_eligible"
                        v-model.number="formData.contract_eligible"
                        class="form-control text-right"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group label="Description" label-for="v-description">
                    <b-form-input
                      disabled
                      id="v-description"
                      v-model="formData.description"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group label="Details" label-for="v-details">
                    <quill-editor
                      disabled
                      ref="quillEditorRef"
                      v-model="content"
                      id="v-details"
                      :options="{ theme: 'snow' }"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>Customer Information</h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Company Name" label-for="v-ticket-no">
                    <b-form-input
                      v-model="formData.company_name"
                      id="v-ticket-no"
                      disabled
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>Address</h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Building/Block/House No and Street Name"
                    label-for="v-addr1"
                  >
                    <b-form-input
                      disabled
                      v-model="formData.addr1"
                      id="v-addr1"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Unit # and Building Name"
                    label-for="v-addr2"
                  >
                    <b-form-input
                      disabled
                      v-model="formData.addr2"
                      id="v-addr2"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Country" label-for="v-country">
                    <b-form-input
                      disabled
                      v-model="formData.country"
                      id="v-ticket-no"
                    />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Postal" label-for="v-postal">
                    <b-form-input
                      disabled
                      v-model="formData.postal"
                      id="v-postal"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>Contact Person</h2>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Contact Person" label-for="v-poc">
                    <b-form-input disabled v-model="formData.poc" id="v-poc" />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Designation" label-for="v-designation">
                    <b-form-input
                      disabled
                      v-model="formData.designation"
                      id="v-designation"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Email" label-for="v-email">
                    <b-form-input
                      disabled
                      v-model="formData.email"
                      id="v-email"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="6">
                  <b-form-group label="Telephone" label-for="v-tel">
                    <b-form-input disabled v-model="formData.tel" id="v-tel" />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Mobile" label-for="v-mobile">
                    <b-form-input
                      disabled
                      v-model="formData.mobile"
                      id="v-mobile"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="3">
                  <b-form-group label="Fax" label-for="v-fax">
                    <b-form-input disabled v-model="formData.fax" id="v-fax" />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>GST and Currency</h2>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="4">
                  <b-form-group label="GST" label-for="v-ticket-no">
                    <b-form-input
                      disabled
                      v-model="formData.gst"
                      id="v-ticket-no"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="2">
                  <b-form-group label="GST Percent" label-for="v-ticket-no">
                    <b-form-input
                      disabled
                      type="number"
                      v-model="formData.gst_percent"
                      id="v-ticket-no"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="4">
                  <b-form-group label="Currency" label-for="v-ticket-no">
                    <b-form-input
                      disabled
                      v-model="formData.currency_id"
                      id="v-ticket-no"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="2">
                  <b-form-group label="Exchange Rate" label-for="v-ticket-no">
                    <b-form-input
                      disabled
                      type="number"
                      v-model.number="formData.exchange_rate"
                      id="v-ticket-no"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>Other</h2>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group>
                    FILE:
                    <span
                      class="text-primary cursor-pointer"
                      @click="onViewThumbnail"
                    >
                      {{ formData.filename }}
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>Add Products</h2>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group>
                    <ContractCoverages
                      :isDisabled="true"
                      :data-source="dataSourceContractCoverages"
                      :formData="formData"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <h2>Products and Services Covered</h2>
                </b-col>
              </b-row>

              <b-row>
                <b-col>
                  <b-form-group>
                    <ContractServices
                      :isDisabled="true"
                      :data-source="dataSourceContractServices"
                      :formData="formData"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>

            <b-tab title="Notes">
              <Notes module="contracts" :header-id="getFormDataID" />
            </b-tab>
            <b-tab title="Documents">
              <Documents module="contracts" :header-id="getFormDataID" />
            </b-tab>
            <b-tab title="Logs">
              <Logs module="contracts" />
            </b-tab>
            <b-tab title="Comments">
              <Comments module="contracts" />
            </b-tab>
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import contractHeadersApi from '@api/contract_headers';

import ContractCoverages from './components/ContractCoverages.vue';
import ContractServices from './components/ContractServices.vue';

export default {
  name: 'ContractsViewPage',
  components: {
    ContractCoverages,
    ContractServices,
  },
  data: () => ({
    activeTab: 0,
    dataSourceContractCoverages: [],
    dataSourceContractServices: [],
    content: '',
    previewStartDate: null,
    previewEndDate: null,
    formData: {
      id: 0,
      company_id: null,
      company_name: null,
      contract_type: null,
      chargeable_type: null,
      contract_eligible: null,
      description: null,
      details: null,
      refnum: null,
      gst: null,
      gst_percent: null,
      currency_id: null,
      exchange_rate: null,
      addr1: null,
      addr2: null,
      postal: null,
      country: 'Singapore',
      tel: null,
      mobile: null,
      email: null,
      designation: null,
      poc: null,
      fax: null,
      status: 'O',

      filename: null,
      base64: null,
    },
    fileUpload: null,
  }),
  mounted() {
    if (this.$route.query.id) {
      this.loadContract();
    }
  },
  computed: {
    chargeableType: {
      get() {
        return this.formData.chargeable_type === 'H' ? 'Hours' : 'Token';
      },
    },
    getFormDataID: {
      get() {
        return parseInt(this.formData.id);
      },
    },
  },
  methods: {
    loadContract() {
      contractHeadersApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          const { start_date, end_date, details } = data;

          this.content = details;
          if (data.contract_coverages.data) {
            this.dataSourceContractCoverages = data.contract_coverages.data;
          }

          if (data.contract_services.data) {
            this.dataSourceContractServices = data.contract_services.data;
          }

          const splitDate = (date) => {
            return this.displayDateTimeFromUnix(date).split(' ');
          };

          if (start_date) {
            const splitStartDate = splitDate(start_date);
            this.previewStartDate = splitStartDate[0];
          }

          if (end_date) {
            const splitEndDate = splitDate(end_date);
            this.previewEndDate = splitEndDate[0];
          }

          this.formData = data;
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onEdit() {
      const url = `/pages/agreements/contracts/manage?id=${this.$route.query.id}`;
      this.$router.push(url);
    },
    goBack() {
      this.$router.push('/pages/agreements/contracts');
    },
    onDuplicate() {
      const url = `/pages/agreements/contracts/manage?id=${this.$route.query.id}&duplicate=true`;
      this.$router.push(url);
    },
    onViewThumbnail() {
      const params = {
        model: 'contracts',
        id: this.formData.id,
        thumbnail: this.formData.file,
      };

      window.open(this.getImage(params), '_blank');
    },
  },
};
</script>

<style lang="scss"></style>
